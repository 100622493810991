import Cookies from 'js-cookie';
import { addClass, loadCSS, removeClass } from './DOMHelpers';
import {
  body,
  widgetHolder,
  createBubbleHolder,
  createBubbleIcon,
  bubbleImg,
  chatBubble,
  closeBubble,
  bubbleHolder,
  createNotificationBubble,
  onClickChatBubble,
  onBubbleClick,
  setBubbleText,
} from './bubbleHelpers';
import { dispatchWindowEvent } from './customEventHelper';
import Customer from "./CustomerService";

import getPlatform from "../services/platformAdapters";

const EVENT_NAME = 'luxlock:ready';

export const IFrameHelper = {
  getUrl({ baseUrl, clientId, clientSecret }) {
    return `${baseUrl}?client_id=${clientId}&client_secret=${clientSecret}`;
  },
  createFrame: ({ baseUrl, clientId, clientSecret }) => {
    if (IFrameHelper.getAppFrame()) {
      return;
    }

    loadCSS();
    const iframe = document.createElement('iframe');
    const llCookie = Cookies.getJSON('ll_authToken');
    let widgetUrl = IFrameHelper.getUrl({ baseUrl, clientId, clientSecret });

    if (llCookie) {
      widgetUrl = `${widgetUrl}&ll_authToken=${llCookie.access_token}`;
    }
    iframe.src = widgetUrl;

    iframe.id = 'luxlock_live_chat_widget';
    iframe.style.visibility = 'hidden';

    let holderClassName = `luxlock-widget-holder luxlock--hide luxlock-elements--${window.$luxlock.position}`;
    if (window.$luxlock.hideMessageBubble) {
      holderClassName += ` luxlock-widget--without-bubble`;
    }
    addClass(widgetHolder, holderClassName);
    widgetHolder.appendChild(iframe);
    body.appendChild(widgetHolder);
    IFrameHelper.initPostMessageCommunication();
    IFrameHelper.initLocationListener();
    // IFrameHelper.initWindowSizeListener();
    IFrameHelper.preventDefaultScroll();
  },
  getAppFrame: () => document.getElementById('luxlock_live_chat_widget'),
  getBubbleHolder: () => document.getElementsByClassName('luxlock--bubble-holder'),
  sendMessage: (key, value) => {
    const element = IFrameHelper.getAppFrame();
    element.contentWindow.postMessage(
      `luxlock-widget:${JSON.stringify({ event: key, ...value })}`,
      '*'
    );
  },
  initLocationListener: () => {
    window.onhashchange = () => {
      IFrameHelper.setCurrentUrl();
    };
  },
  initPostMessageCommunication: () => {
    window.onmessage = e => {
      if (
        typeof e.data !== 'string' ||
        e.data.indexOf('luxlock-widget:') !== 0
      ) {
        return;
      }
      const message = JSON.parse(e.data.replace('luxlock-widget:', ''));
      if (typeof IFrameHelper.events[message.event] === 'function') {
        IFrameHelper.events[message.event](message);
      }
    };
  },
  initWindowSizeListener: () => {
    // luxlockOn(window, 'resize', () => IFrameHelper.toggleCloseButton());
  },
  preventDefaultScroll: () => {
    widgetHolder.addEventListener('wheel', event => {
      const deltaY = event.deltaY;
      const contentHeight = widgetHolder.scrollHeight;
      const visibleHeight = widgetHolder.offsetHeight;
      const scrollTop = widgetHolder.scrollTop;

      if (
        (scrollTop === 0 && deltaY < 0) ||
        (visibleHeight + scrollTop === contentHeight && deltaY > 0)
      ) {
        event.preventDefault();
      }
    });
  },
  events: {
    loaded: () => {
      window.$luxlock.hasLoaded = true;
      const token = Cookies.getJSON('ll_authToken');
      const flowId = Customer.getFlowId();
      const guestToken = Customer.getGuestToken();

      const isProactiveMessage = localStorage.isProactiveMessage === "false" ? false : true;

      IFrameHelper.sendMessage('set-config', {
        clientId: window.$luxlock.clientId,
        clientSecret: window.$luxlock.clientSecret,
        platform: window.$luxlock.platform,
        locale: window.$luxlock.locale,
        position: window.$luxlock.position,
        showProactiveMessage: window.$luxlock.showProactiveMessage,
        proactiveMessageTitle: window.$luxlock.proactiveMessageTitle,
        proactiveMessageBody: window.$luxlock.proactiveMessageBody,
        isProactiveMessage,
        hideMessageBubble: window.$luxlock.hideMessageBubble,
        customer: {
          ...window.$luxlock.customer,
          flow_id: flowId,
          guest_token: guestToken,
          user_agent: window.navigator.userAgent,
        },
        token,
      });
      IFrameHelper.onLoad({
        // widgetColor: message.config.channelConfig && message.config.channelConfig.widgetColor,
        isProactiveMessage,
        launcherColor: window.$luxlock.launcherColor
      });

      localStorage.isProactiveMessage = false;

      IFrameHelper.setCurrentUrl();
      // IFrameHelper.toggleCloseButton();

      if (window.$luxlock.user) {
        IFrameHelper.sendMessage('set-user', window.$luxlock.user);
      }

      dispatchWindowEvent(EVENT_NAME);
    },

    addToCart(message){
      const { productTitle } = message;
      const { platform } = window.$luxlock;

      getPlatform(platform).addToCart(message)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          } else {
            response.json();
          }
        })
        .then((data) => {
          IFrameHelper.sendMessage('add-to-cart-success', { productTitle: productTitle } );
        })
        .catch(() => {
          IFrameHelper.sendMessage('add-to-cart-failure', { productTitle: productTitle });
        });
    },

    setBubbleLabel(message) {
      if (window.$luxlock.hideMessageBubble) {
        return;
      }
      setBubbleText(window.$luxlock.launcherTitle || message.label);
    },

    toggleBubble: () => {
      onBubbleClick();
    },

    toggleProactive: () => {
      removeClass(widgetHolder, 'luxlock-widget-holder--proactive');
    },

    onBubbleToggle: isOpen => {
      if (!isOpen) {
        IFrameHelper.events.resetUnreadMode();
      } else {
        IFrameHelper.pushEvent('webwidget.triggered');
      }
    },

    setUnreadMode: message => {
      const { unreadMessageCount } = message;
      const { isOpen } = window.$luxlock;
      const toggleValue = true;

      if (!isOpen && unreadMessageCount > 0) {
        IFrameHelper.sendMessage('set-unread-view');
        onBubbleClick({ toggleValue });
        const holderEl = document.querySelector('.luxlock-widget-holder');
        addClass(holderEl, 'has-unread-view');
      }
    },

    resetUnreadMode: () => {
      IFrameHelper.sendMessage('unset-unread-view');
      IFrameHelper.events.removeUnreadClass();
    },

    removeUnreadClass: () => {
      const holderEl = document.querySelector('.luxlock-widget-holder');
      removeClass(holderEl, 'has-unread-view');
    },

    onLogin: (message) => {
      if(message.authToken) {
        Cookies.set('ll_authToken', message.authToken);
      }
    },

    onLogout: () => {
      Customer.removeFlowId();
      window.$luxlock.reset();
    }

  },
  pushEvent: (eventName) => {
    IFrameHelper.sendMessage('push-event', { eventName });
  },
  onLoad: ({ isProactiveMessage, launcherColor }) => {
    const iframe = IFrameHelper.getAppFrame();
    iframe.style.visibility = '';
    iframe.setAttribute('id', `luxlock_live_chat_widget`);

    if(window.$luxlock.isOpen) {
      removeClass(widgetHolder, 'luxlock--hide');
    }

    if(isProactiveMessage){
      addClass(widgetHolder, 'luxlock-widget-holder--proactive');
    }

    if (IFrameHelper.getBubbleHolder().length) {
      return;
    }

    createBubbleHolder();

    if (!window.$luxlock.hideMessageBubble) {
      const chatIcon = createBubbleIcon({
        className: `luxlock-widget-bubble`,
        src: bubbleImg,
        target: chatBubble,
      });

      const closeIcon = closeBubble;
      const closeIconclassName = `luxlock-elements--${window.$luxlock.position} luxlock-widget-bubble luxlock--close ${!window.$luxlock.isOpen && 'luxlock--hide'}`;
      addClass(closeIcon, closeIconclassName);

      chatIcon.style.backgroundColor = launcherColor;
      closeIcon.style.backgroundColor = launcherColor;

      bubbleHolder.appendChild(chatIcon);
      bubbleHolder.appendChild(closeIcon);
      bubbleHolder.appendChild(createNotificationBubble());
      onClickChatBubble();
    }
  },
  setCurrentUrl: async () => {
    const referrerURL = window.location.href;

    const { platform } = window.$luxlock;
    const path = window.location.pathname + window.location.search;
    const { title, referrer } = document;

    const messageData = {
      page_data: {
        path,
        referrer,
        referrerURL,
        title,
      },
    };

    try {
      const cart_token = await getPlatform(platform).getCart();

      IFrameHelper.sendMessage('link', {
        ...messageData,
        customer_data: {
          cart_token,
        }
      });
    } catch (error) {
      IFrameHelper.sendMessage('link', messageData);
    }
  },
  // toggleCloseButton: () => {
  //   if (window.matchMedia('(max-width: 668px)').matches) {
  //     IFrameHelper.sendMessage('toggle-close-button', {
  //       showClose: true,
  //     });
  //   } else {
  //     IFrameHelper.sendMessage('toggle-close-button', {
  //       showClose: false,
  //     });
  //   }
  // },
};
