import Cookies from 'js-cookie';
import { IFrameHelper } from './sdk/IFrameHelper';
import { getBubbleView } from './sdk/bubbleHelpers';
import md5 from 'md5';

const REQUIRED_USER_KEYS = ['avatar_url', 'email', 'full_name'];

const ALLOWED_USER_ATTRIBUTES = [...REQUIRED_USER_KEYS, 'identifier_hash'];

export const getUserCookieName = () => {
  const SET_USER_COOKIE_PREFIX = 'll_user_';
  const { clientId, clientSecret} = window.$luxlock;
  return `${SET_USER_COOKIE_PREFIX}${clientId}${clientSecret}`;
};

export const getUserString = ({ identifier = '', user }) => {
  const userStringWithSortedKeys = ALLOWED_USER_ATTRIBUTES.reduce(
    (acc, key) => `${acc}${key}${user[key] || ''}`,
    ''
  );
  return `${userStringWithSortedKeys}identifier${identifier}`;
};

const computeHashForUserData = (...args) => md5(getUserString(...args));

export const hasUserKeys = user =>
  REQUIRED_USER_KEYS.reduce((acc, key) => acc || !!user[key], false);

const runSDK = ({ clientId, clientSecret }) => {
  const baseUrl = process.env.CHAT_IFRAME_URL;

  const luxlockSettings = window.luxlockSettings || {};

  window.$luxlock = {
    baseUrl,
    clientId,
    clientSecret,
    hasLoaded: false,
    customer: luxlockSettings.customer,
    isOpen: localStorage.isLuxLockOpen === 'true' ? true : false,
    hideMessageBubble: luxlockSettings.hideMessageBubble || false,
    launcherTitle: luxlockSettings.launcherTitle || '',
    launcherColor: luxlockSettings.launcherColor || '#c79c39',
    locale: luxlockSettings.locale,
    platform: luxlockSettings.platform,
    position: luxlockSettings.position === 'left' ? 'left' : 'right',
    showProactiveMessage: luxlockSettings.showProactiveMessage || false,
    proactiveMessageTitle: luxlockSettings.proactiveMessageTitle || 'Live Shopping',
    proactiveMessageBody: luxlockSettings.proactiveMessageBody || "Shopping online shouldn't mean shopping alone. Connect to your personal shopper for the ultimate experience.",
    type: getBubbleView(luxlockSettings.type),

    toggle() {
      IFrameHelper.events.toggleBubble();
    },

    setUser(identifier, user) {
      if (typeof identifier !== 'string' && typeof identifier !== 'number') {
        throw new Error('Identifier should be a string or a number');
      }

      if (!hasUserKeys(user)) {
        throw new Error(
          'User object should have one of the keys [avatar_url, email, name]'
        );
      }

      const userCookieName = getUserCookieName();
      const existingCookieValue = Cookies.get(userCookieName);
      const hashToBeStored = computeHashForUserData({ identifier, user });
      if (hashToBeStored === existingCookieValue) {
        return;
      }

      window.$luxlock.identifier = identifier;
      window.$luxlock.user = user;
      IFrameHelper.sendMessage('set-user', { identifier, user });
      Cookies.set(userCookieName, hashToBeStored, {
        expires: 365,
        sameSite: 'Lax',
      });
    },

    setCustomAttributes(customAttributes = {}) {
      if (!customAttributes || !Object.keys(customAttributes).length) {
        throw new Error('Custom attributes should have atleast one key');
      } else {
        IFrameHelper.sendMessage('set-custom-attributes', { customAttributes });
      }
    },

    deleteCustomAttribute(customAttribute = '') {
      if (!customAttribute) {
        throw new Error('Custom attribute is required');
      } else {
        IFrameHelper.sendMessage('delete-custom-attribute', {
          customAttribute,
        });
      }
    },

    setLocale(localeToBeUsed = 'en') {
      IFrameHelper.sendMessage('set-locale', { locale: localeToBeUsed });
    },

    reset() {
      if (window.$luxlock.isOpen) {
        IFrameHelper.events.toggleBubble()
        // setTimeout(function(){ IFrameHelper.events.toggleBubble() }, 3000);
      }

      Cookies.remove('ll_authToken');
      Cookies.remove(getUserCookieName());

      const iframe = IFrameHelper.getAppFrame();
      iframe.src = IFrameHelper.getUrl({
        baseUrl: window.$luxlock.baseUrl,
        clientId: window.$luxlock.clientId,
        clientSecret: window.$luxlock.clientSecret,
      });
    },
  };

  IFrameHelper.createFrame({
    baseUrl,
    clientId,
    clientSecret,
  });
};

window.luxlockSDK = {
  run: runSDK,
};
