// import Cookies from "js-cookie";
import { nanoid } from "nanoid/non-secure";

export const Customer = {
  getFlowId() {
    // let flowId = Cookies.get("flowId");
    let flowId = sessionStorage.getItem("flow_id");

    if (flowId) {
      return flowId;
    }
    flowId = nanoid(16);
    // Cookies.set("flow_id", flowId)
    sessionStorage.setItem("flow_id", flowId);

    return flowId;
  },

  getGuestToken() {
    // let guestToken = Cookies.get("guestToken");
    let guestToken = sessionStorage.getItem("guest_token");

    if (guestToken) {
      return guestToken;
    }

    guestToken = nanoid(16);
    sessionStorage.setItem("guest_token", guestToken);
    // Cookies.set("guestToken", guestToken);

    return guestToken;
  },

  removeFlowId() {
    sessionStorage.removeItem("flow_id");
    // Cookies.remove("flowId");
  },
};

export default Customer;
