import { get, post } from "../apiMethods";

const CART_URL = "/cart.js";
const CART_ADD_URL = "/cart/add.js";

export const Shopify = {
  async getCart() {
    return get(CART_URL).then((data) => data.token);
  },

  async addToCart(e) {
    const body = {
      quantity: e.quantity || 1,
      id: e.variantId,
    };

    if(e.refreshPage){
      return post(CART_ADD_URL, body, setTimeout("window.location.reload()", 600));
    }

    return post(CART_ADD_URL, body);
  },
};

export default Shopify;
