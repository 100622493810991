import { addClass, toggleClass, removeClass, luxlockOn } from './DOMHelpers';
import { IFrameHelper } from './IFrameHelper';

const BUBBLE_DESIGN = ['standard', 'texted', 'expanded'];

export const bubbleImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAUVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////8IN+deAAAAGnRSTlMAAwgJEBk0TVheY2R5eo+ut8jb5OXs8fX2+cjRDTIAAADsSURBVHgBldZbkoMgFIThRgQv8SKKgGf/C51UnJqaRI30/9zfe+NQUQ3TvG7bOk9DVeCmshmj/CuOTYnrdBfkUOg0zlOtl9OWVuEk4+QyZ3DIevmSt/ioTvK1VH/s5bY3YdM9SBZ/mUUyWgx+U06ycgp7D8msxSvtc4HXL9BLdj2elSEfhBJAI0QNgJEBI1BEBsQClVBVGDgwYOLAhJkDM1YOrNg4sLFAsLJgZsHEgoEFFQt0JAFGFjQsKAMJ0LFAexKgZYFyJIDxJIBNJEDNAtSJBLCeBDCOBFAPzwFA94ED+zmhwDO9358r8ANtIsMXi7qVAwAAAABJRU5ErkJggg==';

export const body = document.getElementsByTagName('body')[0];
export const widgetHolder = document.createElement('div');

export const bubbleHolder = document.createElement('div');
export const chatBubble = document.createElement('div');
export const closeBubble = document.createElement('div');
export const notificationBubble = document.createElement('span');

export const getBubbleView = type =>
  BUBBLE_DESIGN.includes(type) ? type : BUBBLE_DESIGN[0];

export const isStandartView = type => getBubbleView(type) === BUBBLE_DESIGN[0];
export const isTextedView = type => getBubbleView(type) === BUBBLE_DESIGN[1];
export const isExtendedView = type => getBubbleView(type) === BUBBLE_DESIGN[2];

export const setBubbleText = bubbleText => {
  if (isTextedView(window.$luxlock.type)) {
    const textNode = document.getElementById('luxlock-widget--texted__text');
    textNode.innerHTML = bubbleText;
  }
};

export const createBubbleIcon = ({ className, src, target }) => {
  let bubbleClassName = `${className} luxlock-elements--${window.$luxlock.position}`;

  if (!isExtendedView(window.$luxlock.type)) {
    const bubbleIcon = document.createElement('img');
    bubbleIcon.src = src;
    bubbleIcon.alt = 'bubble-icon';
    target.appendChild(bubbleIcon);

    bubbleClassName += ` ${window.$luxlock.isOpen && 'luxlock--hide'}`;
  }

  if (isTextedView(window.$luxlock.type)) {
    const textNode = document.createElement('div');
    textNode.id = 'luxlock-widget--texted__text';
    textNode.innerHTML = '';
    target.appendChild(textNode);
    bubbleClassName += ' luxlock-widget--texted';
  }

  if (isExtendedView(window.$luxlock.type)) {
    addClass(closeBubble, 'luxlock--hide');
    addClass(widgetHolder, 'luxlock-widget-holder--extended');
    removeClass(chatBubble, 'luxlock--hide');
    bubbleClassName += ` luxlock-widget-bubble--extended ${window.$luxlock.isOpen && 'luxlock-widget-bubble--extended-opened'}`;
  }

  target.className = bubbleClassName;
  return target;
};

export const createBubbleHolder = () => {
  addClass(bubbleHolder, 'luxlock--bubble-holder');
  body.appendChild(bubbleHolder);
};

export const createNotificationBubble = () => {
  addClass(notificationBubble, 'luxlock--notification');
  return notificationBubble;
};

export const onBubbleClick = (props = {}) => {
  const { toggleValue } = props;
  const { isOpen } = window.$luxlock;
  if (isOpen !== toggleValue) {
    const newIsOpen = toggleValue === undefined ? !isOpen : toggleValue;
    window.$luxlock.isOpen = newIsOpen;
    localStorage.isLuxLockOpen = newIsOpen;

    toggleClass(widgetHolder, 'luxlock--hide');

    if (!isExtendedView(window.$luxlock.type)) {
      toggleClass(chatBubble, 'luxlock--hide');
      toggleClass(closeBubble, 'luxlock--hide');
    }

    if (isExtendedView(window.$luxlock.type)) {
      if(newIsOpen){
        removeClass(chatBubble, 'luxlock-widget-bubble--extended-closing');
        addClass(chatBubble, 'luxlock-widget-bubble--extended-opening');
      } else {
        removeClass(chatBubble, 'luxlock-widget-bubble--extended-opening');
        addClass(chatBubble, 'luxlock-widget-bubble--extended-closing');
      }
    }

    IFrameHelper.events.onBubbleToggle(newIsOpen);
  }
};

export const onClickChatBubble = () => {
  luxlockOn(bubbleHolder, 'click', onBubbleClick);
};
