import { getIn } from "../../../utils/ramda";
import { get, post } from "../apiMethods";

const BASE_URL = "/api/storefront";
const CART_URL = `${BASE_URL}/cart`;
const CARTS_URL = `${BASE_URL}/carts`;

export const BigCommerce = {
  async getCart() {
    return get(CARTS_URL).then((data) => getIn("0.id", data));
  },

  async addToCart(e) {
    const lineItems = [
      {
        quantity: e.quantity || 1,
        productId: e.productId,
        optionSelections: e.optionSelections,
      },
    ];

    const cartId = await this.getCart();

    if (cartId) {
      return post(`${CARTS_URL}/${cartId}/items`, { lineItems });
    }

    return post(`${CART_URL}`, { lineItems });
  },
};

export default BigCommerce;
