import { anyPass, isEmpty, isNil, complement, path, pathOr, split } from "ramda";

export const getIn = (p, a) => {
  if (typeof p === "string") {
    return path(split(".", p), a);
  }

  return path(p, a);
};

export const getInOr = (d, p, a) => {
  if (typeof p === "string") {
    return pathOr(d, split(".", p), a);
  }

  return pathOr(d, p, a);
};

export const isNotDefined = anyPass([isEmpty, isNil]);

export const isDefined = complement(isNotDefined);
