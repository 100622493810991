import ExtendedLogoIcon from "../assets/logo.jpg";

export const SDK_CSS = `
.luxlock-widget-holder {
  box-shadow: 0 5px 40px rgba(0, 0, 0, .16) !important;
  opacity: 1;
  overflow: hidden !important;
  position: fixed !important;
  transition-duration: 0.5s, 0.5s;
  transition-property: opacity, bottom;
  z-index: 2147483000 !important;
}

.luxlock-widget-holder iframe {
  border: 0;
  height: 100% !important;
  width: 100% !important;
}

.luxlock-widget-holder.has-unread-view {
  border-radius: 0 !important;
  bottom: 94px;
  box-shadow: none !important;
}

.luxlock-widget-bubble {
  background-color: #c79c39;
  border-radius: 100px !important;
  bottom: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, .16) !important;
  cursor: pointer;
  height: 64px !important;
  position: fixed;
  width: 64px !important;
  z-index: 2147482000 !important;
  user-select: none;
}

.luxlock-widget-bubble.luxlock-widget--texted {
  bottom: 24px;
  display: flex;
  height: 48px !important;
  width: auto !important;
}

.luxlock-widget-bubble.luxlock-widget--texted div {
  align-items: center;
  color: #fff;
  display: flex;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  padding-right: 20px;
  width: auto !important;
}

.luxlock-widget-bubble.luxlock-widget--texted img {
  height: 20px;
  margin: 14px 8px 14px 16px;
  width: 20px;
}

.luxlock-widget-bubble.luxlock-elements--left {
  left: 20px;
}

.luxlock-widget-bubble.luxlock-elements--right {
  right: 20px;
}

.luxlock-widget-bubble:hover {
  background-color: #c79c39;
  box-shadow: 0 8px 32px rgba(0, 0, 0, .4) !important;
}

.luxlock-widget-bubble img {
  height: 24px;
  margin: 20px;
  width: 24px;
}

@media only screen and (min-width: 667px) {
  .luxlock-widget-holder.luxlock-elements--left {
    left: 20px;
 }
  .luxlock-widget-holder.luxlock-elements--right {
    right: 20px;
 }
}

.luxlock--close:hover {
  opacity: 1;
}

.luxlock--close::before, .luxlock--close::after {
  background-color: #fff;
  content: ' ';
  height: 24px;
  left: 32px;
  position: absolute;
  top: 20px;
  width: 2px;
}

.luxlock--close::before {
  transform: rotate(45deg);
}

.luxlock--close::after {
  transform: rotate(-45deg);
}

.luxlock--hide {
  bottom: -20000px;
  opacity: 0;
  visibility: hidden !important;
  z-index: -1 !important;
}

.luxlock-widget--without-bubble {
  bottom: 20px !important;
}

.luxlock-widget-bubble--extended{
  display: block;
  box-sizing: border-box;
  transform: scale(0.5);
  right: 0px!important;
  bottom: 0px!important;
  width: 126px!important;
  min-width: 126px!important;
  height: 126px!important;
  min-height: 126px!important;
  border-radius: 100%!important;
  background-image: url("${ExtendedLogoIcon}")!important;
  background-repeat: no-repeat!important;
  animation-name: anim;
  animation-duration: 4s;
  animation-timing-function: steps(48);
  animation-delay: 8s;
  animation-iteration-count: 1;
  animation-play-state: running;
}

.luxlock-widget-bubble--extended-opened{
  background-position: -6144px 0;
  animation-play-state: paused;
}

.luxlock-widget-bubble--extended-opening{
  animation-name: animOpen;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.luxlock-widget-bubble--extended-closing{
  animation-name: animClose;
  animation-delay: 0s;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes anim {
  0% { background-position: 0 0 }
  20% { background-position: -6144px 0 }
  80% { background-position: -6144px 0 }
  100% { background-position: 0 0 }
}

@keyframes animOpen {
  0% { background-position: 0 0 }
  100% { background-position: -6144px 0 }
}

@keyframes animClose {
  0% { background-position: -6144px 0 }
  100% { background-position: 0 0 }
}

@media only screen and (max-width: 667px) {
  .luxlock-widget-holder {
    height: 100%;
    right: 0;
    top: 0;
    width: 100%;
  }
  .luxlock-widget-holder--proactive{
    top: auto;
    height: 380px;
    bottom: 0;
  }
  .luxlock-widget-bubble.luxlock--close {
    bottom: 60px;
    opacity: 0;
    visibility: hidden !important;
    z-index: -1 !important;
  }
}

@media only screen and (min-width: 667px) {
  .luxlock-widget-holder {
    border-radius: 16px !important;
    bottom: 104px;
    height: calc(100% - 104px - 20px);
    min-height: 250px !important;
    width: 400px !important;
 }

 .luxlock-widget-holder--extended{
  bottom: 124px;
  height: calc(100% - 104px - 40px);
 }

 .luxlock-widget-holder--proactive{
    bottom: 124px;
    height: 380px;
 }
}
`;
